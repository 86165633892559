import React from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags';
import IURoleSelector from './components/IURoleSelector';
import MURoleSelector from './components/MURoleSelector/MURoleSelector';
import { RoleSelectorProps } from './type';

const RoleSelector = ({
  user,
  onChange,
  onChangeRole,
  disabled,
  classes,
  errors,
  buttonText,
  showEmail,
  showPassword,
}: RoleSelectorProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  return useNewLogin ? (
    <IURoleSelector
      {...{ user, onChange, onChangeRole, disabled, classes, errors, buttonText, showEmail, showPassword }}
    />
  ) : (
    <MURoleSelector
      {...{ user, onChange, onChangeRole, disabled, classes, errors, buttonText, showEmail, showPassword }}
    />
  );
};

export default RoleSelector;
