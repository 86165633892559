import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import theme from '@instructure/canvas-theme';
import PreApplicationLayout from 'layouts/PreApplicationLayout';
import CustomThemeProvider from 'layouts/CustomThemeProvider';

import reducers from 'reducers/preApplicationReducers';
import { ResponsiveProvider } from 'contexts/ResponsiveProvider';
import { FlagsProvider } from 'hooks/useFeatureFlags';
import SignIn from './containers/PreApplication/SignIn';
import SignUpPage from './containers/PreApplication/SignUp/SignUpPage';
import EmailConfirmation from './containers/PreApplication/EmailConfirmation';
import ForgotPassword from './containers/PreApplication/ForgotPassword';
import ResendConfirmation from './containers/PreApplication/ResendConfirmation';
import ResendUnlock from './containers/PreApplication/ResendUnlock';
import ResetPassword from './containers/PreApplication/ResetPassword';
import MembershipSetup from './containers/PreApplication/MembershipSetup';
import ClaimCompany from './containers/PreApplication/ClaimCompany';
import ProfileSetup from './containers/PreApplication/ProfileSetup';
import SentForgotPassword from './containers/PreApplication/ForgotPassword/SentForgotPassword';

import './lato.css';

theme.use({});

const COMPONENTS = {
  SignIn,
  SignUpPage,
  ForgotPassword,
  ResetPassword,
  ProfileSetup,
  SentForgotPassword,
  ClaimCompany,
  EmailConfirmation,
  ResendConfirmation,
  ResendUnlock,
  MembershipSetup,
};

const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

function renderReact(component, id, props) {
  const Component = COMPONENTS[component];

  render(
    <FlagsProvider value={gon.flags}>
      <ResponsiveProvider>
        <Provider store={store}>
          <CustomThemeProvider>
            <PreApplicationLayout usePartnerLayout={gon.isPartner} footer={Component !== SignUpPage}>
              <Component {...props} />
            </PreApplicationLayout>
          </CustomThemeProvider>
        </Provider>
      </ResponsiveProvider>
    </FlagsProvider>,
    document.getElementById(id),
  );
}

window.renderReactComponent = (component, id, props) => {
  renderReact(component, id, props);
};
