import React, { ChangeEvent, useState } from 'react';
import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { Button } from '@instructure/ui-buttons';
import { Flex } from '@instructure/ui-flex';
import { isEmpty, join } from 'ramda';

import Flash from 'components/Flash';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';
import TextField from 'components/IUTextField';
import { ForgotPasswordProps } from '../types';

const IUForgotPassword = ({ errors }: ForgotPasswordProps) => {
  const [email, setEmail] = useState('');

  const handleChange = () => (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const renderFlash = () => !isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />;
  return (
    <>
      <View as="div" margin="medium none none">
        <Flex display="flex" direction="column">
          <View as="div" margin="none none large">
            <Text size="x-large" weight="bold">
              Forgot password?
            </Text>
          </View>
          <View>
            <Text size="medium" weight="normal" lineHeight="default">
              Having trouble remembering your password? Enter your email address in the field below, and click Send
              Request. We will send you an email with instructions on how to reset your password.
            </Text>
          </View>
        </Flex>

        {renderFlash()}

        <View as="div">
          <form action={Routes.sent_user_password_path()} method="post">
            <View as="div" height="70vh">
              <FormFieldGroup description="">
                <input type="hidden" name="authenticity_token" value={authenticityToken()} />
                <TextField
                  data-node="user_email_field"
                  onChange={handleChange()}
                  name="user[email]"
                  value={email}
                  renderLabel="Email"
                  placeholder="Enter your email address"
                  isRequired
                  interaction="enabled"
                  id="user_email"
                  fullWidth
                />
              </FormFieldGroup>
            </View>
            <View as="div">
              <Flex display="flex" justifyItems="space-between" margin="none xx-small">
                <Button href={Routes.new_user_session_path()} color="primary-inverse">
                  Back to Login
                </Button>
                <Button type="submit" color="primary" interaction="enabled" data-node="reset_password_button">
                  Reset Password
                </Button>
              </Flex>
            </View>
          </form>
        </View>
      </View>
    </>
  );
};

export default IUForgotPassword;
