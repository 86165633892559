import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-use';
import { isEmpty, join } from 'ramda';
import queryString from 'query-string';
import { ArrowBack } from '@material-ui/icons';
import * as Routes from 'Routes';
import { authenticityToken } from 'utils/FetchHelpers';
import { camelize } from 'utils/keysConverter';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Flash from 'components/Flash';
import Loader from 'components/Loader';
import PasswordFields from '../../sharedComponents/PasswordFields';
import RoleSelector from '../../sharedComponents/RoleSelector';

import useStyles from '../useStyles';

const MUProfileSetup = ({ errors, user }) => {
  const classes = useStyles();
  const parsedQueryString = queryString.parse(useLocation().search);
  const redirect = parsedQueryString.redirect_back + useLocation().hash;
  const anchor = useLocation().hash;
  const [userFields, updateUserFields] = useState(camelize(user));

  const handleChange = name => e => {
    updateUserFields({ ...userFields, [name]: e.target.value });
  };

  const handleChangeRole = role => {
    updateUserFields({ ...userFields, userType: userFields.userType !== role ? role : '' });
  };

  let title = null;
  let description = null;
  let formFields = null;
  let icon = null;
  let showBackButton = false;

  switch (userFields.signUpState) {
    case 'password_setup':
      title = 'Set Your Password';
      description = "Let's set your password!";
      formFields = <PasswordFields backToLogin={false} buttonText="Set Password" />;
      icon = <Icon icon="authentication" />;
      break;
    case 'role_setup':
    case 'renew_role':
      title = 'Select Your Role';
      description = 'Please select the role that best describes your responsibilities at your organization.';
      formFields = (
        <RoleSelector
          user={userFields}
          buttonText="Continue"
          onChange={handleChange}
          onChangeRole={handleChangeRole}
          disabled={!userFields.canChangeUserType}
        />
      );
      icon = <Icon icon="welcome" />;
      showBackButton = userFields.canChangeUserType && userFields.userType && userFields.userType !== 'other';
      break;
    default:
      title = 'Profile Setup';
      description = 'Please select the role that best describes your responsibilities at your organization.';
      formFields = (
        <RoleSelector
          showPassword
          user={userFields}
          buttonText="Continue"
          onChange={handleChange}
          onChangeRole={handleChangeRole}
          disabled={!userFields.canChangeUserType}
        />
      );
      icon = <Icon icon="welcome" />;
      showBackButton = userFields.canChangeUserType && userFields.userType && userFields.userType !== 'other';
  }

  if (!title || !description || !formFields || !icon) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {showBackButton && (
        <Button color="transparent" size="xsmall" onClick={() => handleChangeRole('')} className={classes.backButton}>
          <ArrowBack className={classes.arrow} />
          Back
        </Button>
      )}
      <div className={classes.form}>
        {icon}
        <div className={classes.title}>{description}</div>
        {!isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />}
        <form action={Routes.profile_path({ redirect_back: redirect, anchor })} method="post" className={classes.form}>
          <input type="hidden" name="authenticity_token" value={authenticityToken()} />
          <input type="hidden" name="_method" value="patch" />
          {formFields}
        </form>
      </div>
    </>
  );
};

MUProfileSetup.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.shape().isRequired,
};

export default MUProfileSetup;
