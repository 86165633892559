import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from 'components/Button';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import { isBlank } from 'utils/Utils';

import PasswordFields from '../../../PasswordFields';
import styles from '../../styles';
import roles from '../../roles';
import Role from '../Role';

class MURoleSelector extends PureComponent {
  static propTypes = {
    user: PropTypes.shape().isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeRole: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    classes: PropTypes.shape().isRequired,
    errors: PropTypes.shape(),
    buttonText: PropTypes.string,
    showEmail: PropTypes.bool,
    showPassword: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    buttonText: 'Submit',
    showEmail: false,
    showPassword: false,
    errors: {},
  };

  handleSelect = value => {
    this.props.onChangeRole(value);
  };

  renderRoles = roleItems => {
    const { disabled, classes, user } = this.props;

    return roleItems.map(role => (
      <div className={classes.role} key={role.id} data-node="role_section">
        <Role
          id={role.id}
          title={role.title}
          image={role.image}
          listItems={role.descriptions}
          active={user.userType === role.id}
          onSelect={this.handleSelect}
          disabled={disabled}
        />
      </div>
    ));
  };

  render() {
    const { classes, user, onChange, errors, buttonText, showEmail, showPassword } = this.props;
    const activeRole = roles.filter(role => role.id === user.userType);

    return (
      <div className={classes.root}>
        {activeRole.length === 1 ? this.renderRoles(activeRole) : this.renderRoles(roles)}
        <input type="hidden" name="user[user_type]" value={user.userType} />
        {user.userType && user.userType !== 'other' && (
          <div className={classes.textFields}>
            <TextField
              data-node="first_name_field"
              placeholder="Enter first name"
              name="user[first_name]"
              type="text"
              variant="filled"
              label="First Name"
              value={user.firstName}
              onChange={onChange('firstName')}
              helperText="Required *"
              id="firstName"
              autoComplete="given-name"
              fullWidth
            />
            <TextField
              data-node="last_name_field"
              placeholder="Enter last name"
              name="user[last_name]"
              type="text"
              variant="filled"
              label="Last Name"
              value={user.lastName}
              onChange={onChange('lastName')}
              helperText="Required *"
              id="lastName"
              autoComplete="family-name"
              fullWidth
            />
            {showEmail && (
              <TextField
                data-node="user_email_field"
                placeholder="Enter email address"
                name="user[email]"
                type="email"
                variant="filled"
                label="Email Address"
                value={user.email}
                onChange={onChange('email')}
                error={!isBlank(errors.email)}
                helperText={(!isBlank(errors.email) && errors.email) || 'Required *'}
                id="email"
                autoComplete="email"
                fullWidth
              />
            )}
            {showPassword && <PasswordFields submitButton={false} className={classes.password} />}
            {errors.base && (
              <Typography className={classes.errorMessage} variant="caption">
                {errors.base}
              </Typography>
            )}

            <div className={classes.actions}>
              <Button data-node="submit_button" type="submit" fullWidth>
                {buttonText}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(MURoleSelector);
