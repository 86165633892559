import React, { ChangeEvent, ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-use';
import queryString from 'query-string';
import { View } from '@instructure/ui-view';
import { Text } from '@instructure/ui-text';
import { FormFieldGroup } from '@instructure/ui-form-field';
import { isEmpty, join } from 'ramda';

import * as Routes from 'Routes';
import { camelize } from 'utils/keysConverter';
import { authenticityToken } from 'utils/FetchHelpers';
import PasswordFields from 'containers/PreApplication/sharedComponents/PasswordFields';
import RoleSelector from 'containers/PreApplication/sharedComponents/RoleSelector';
import Loader from 'components/Loader';
import Flash from 'components/Flash';

import IUButton from 'components/IUButton';
import { ProfileSetupProps } from '../types';

interface QueryParams {
  redirect_back: string;
  [key: string]: string;
}

const IUProfileSetup = ({ errors, user }: ProfileSetupProps) => {
  const search = useLocation().search || '';
  const parsedQueryString = queryString.parse(search) as QueryParams;
  const redirect = parsedQueryString.redirect_back + useLocation().hash;
  const anchor = useLocation().hash;
  const [userFields, setUserFields] = useState(camelize(user));

  const handleChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUserFields({ ...userFields, [name]: value });
  };

  const handleChangeRole = (role: string) => {
    setUserFields({ ...userFields, userType: userFields.userType !== role ? role : '' });
  };

  let title = '';
  let description = '';
  let formFields: ReactElement | null = null;
  let showBackButton = false;
  const validUserType = userFields.userType && userFields.userType !== '';

  switch (userFields.signUpState) {
    case 'password_setup':
      title = 'Set Your Password';
      description =
        'Welcome back. To complete this process, type in a new password of your choice. The following criteria applies when selecting your new password:';
      formFields = <PasswordFields backToLogin={false} buttonText="Set Password" />;
      break;
    case 'role_setup':
    case 'renew_role':
      title = !validUserType ? 'Select Your Role' : 'Registration';
      description = !validUserType
        ? 'Please select the role that best describes your responsibilities at your organization.'
        : 'Create your account';
      formFields = (
        <RoleSelector
          user={userFields}
          buttonText="Continue"
          onChange={handleChange}
          onChangeRole={handleChangeRole}
          disabled={!userFields.canChangeUserType}
        />
      );
      showBackButton = userFields.canChangeUserType && userFields.userType && userFields.userType !== 'other';
      break;
    default:
      title = !validUserType ? 'Select Your Role' : 'Registration';
      description = !validUserType
        ? 'Please select the role that best describes your responsibilities at your organization.'
        : 'Create your account';
      formFields = (
        <RoleSelector
          showPassword
          user={userFields}
          buttonText="Continue"
          onChange={handleChange}
          onChangeRole={handleChangeRole}
          disabled={!userFields.canChangeUserType}
        />
      );
      showBackButton = userFields.canChangeUserType && userFields.userType && userFields.userType !== 'other';
  }

  if (!title || !description || !formFields) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {showBackButton && <IUButton onClick={() => handleChangeRole('')}>Back</IUButton>}
      <View as="div" margin="medium none none">
        <Text weight="bold" size="x-large">
          {title}
        </Text>
        <View as="form" padding="large none none none">
          <form action={Routes.profile_path({ redirect_back: redirect, anchor })} method="post">
            {!isEmpty(errors) && <Flash flash={['alert', join(', ', errors)]} />}
            <FormFieldGroup description={<Text>{description}</Text>}>
              <input type="hidden" name="authenticity_token" value={authenticityToken()} />
              <input type="hidden" name="_method" value="patch" />
              {formFields}
            </FormFieldGroup>
          </form>
        </View>
      </View>
    </>
  );
};

export default IUProfileSetup;
