import React from 'react';
import useFeatureFlags from 'hooks/useFeatureFlags';

import MUForgotPassword from './MUForgotPassword/MUForgotPassword';
import IUForgotPassword from './IUForgotPassword/IUForgotPassword';
import { ForgotPasswordProps } from './types';

const ForgotPassword = ({ errors }: ForgotPasswordProps) => {
  const useNewLogin = useFeatureFlags('ff_new_login');
  return useNewLogin ? <IUForgotPassword errors={errors} /> : <MUForgotPassword errors={errors} />;
};

export default ForgotPassword;
